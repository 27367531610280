#nav{
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    width: 100vw;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.114);
    backdrop-filter: blur(10px);
}

.hidden {
    visibility: hidden;
}

.hamburger {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: space-between;
    width: 100%;
    height: 25px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
}

#main-navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    width: 100vw;
    z-index: 1000;
    margin: 0vh auto 0vh;
    padding-top: 1px;
    font-family: 'Typeface', sans-serif;
}

.nav-bar {
    display: flex;
    justify-content: center;
    margin-right: 5vw;
}


.nav-bar a {
    margin: 0 15px;
    text-decoration: none; 
    color: rgb(156, 156, 156);
    font-size: 1.3rem; 
    padding-left: 20px;
    padding-right: 20px;
}

.nav-bar a:hover {
    color: white;
}

#logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 6vw;
}

.name-logo {
    text-align: right;
    align-items: baseline;
    text-decoration: none;
    color: white;
}

#logo a {
    font-size: 3rem;
    font-weight: 900;
    line-height: 80%;
    letter-spacing: -2px;
}

.BE-logo {
    width: 4.1rem;
    height: auto;
    padding: 0px 4px 19px;
}

.nav-bar button {
    margin: 0 15px;

    text-decoration: none; 
    color: rgb(156, 156, 156);
    font-size: 1.25rem;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgba(0, 0, 0, 0.000001);
    backdrop-filter: blur(20px);
    border: none;
    font-family: 'Typeface', sans-serif;
}

.nav-bar button:hover {
    color: white;
}


/* RESPONSIVENESS MOBILE */ 
@media (max-width: 600px) {
    #logo {
        margin-left: 6vw;
    }

    #logo a {
        font-size: 2rem;
        font-weight: 600;
        line-height: 80%;
        letter-spacing: -2px;
    }
    
    .BE-logo {
        width: 3rem;
        height: auto;
        padding: 0px 4px 19px;
    }

    .hamburger-nav-bar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: sticky;
        top: 0;
        z-index: 1000;
        width: 100%;
        height: 30vh;
    }

    .hamburger-nav-bar {
        display: flex;
        justify-content: center;
        gap: 2vh;
        font-weight: 100;
        position: sticky;
        top: 0;
        z-index: 1000;
        width: 100vw;
        }
    
    
    .hamburger-nav-bar a {
        margin: 0 15px;
        text-decoration: none; 
        color: white;
        font-size: 1.3rem; 
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .hamburger-nav-bar a:hover {
        color: rgb(156, 156, 156);
    }

    .hamburger-button {
        margin: 0 15px;
        text-decoration: none; 
        color: white;
        background-color: rgba(0, 0, 0, 0.000001);
        font-size: 1.3rem;
        padding-left: 20px;
        padding-right: 20px;
        border: none;
        font-family: 'Typeface', sans-serif;
    }
    
    .hamburger-button:hover {
        color: rgb(156, 156, 156);
    }

    .ham-button {
        margin: 0 15px;
        text-decoration: none; 
        color: white;
        font-size: 1.7rem;
        padding-left: 20px;
        padding-right: 20px;
        background-color: rgba(0, 0, 0, 0.000001);
        border: none;
        font-family: 'Typeface', sans-serif;
        transform: rotate(-90deg);
    }

    .ham-button:hover {
        color: rgb(156, 156, 156);
    }
    
    
};


