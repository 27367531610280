.resume-header {
    font-size: 4rem;
    letter-spacing: -px;
    margin-bottom: 1vh;
    text-shadow:         
        0 0 5px white,
        0 0 10px white,
        0 0 20px white,
        0 0 40px white;
    color: black;
}

.resume-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
}

.resume-image-container {
    display: flex;
    align-self: center;
    justify-content: center;
    margin-bottom: 0px;
}

.resume-image {
    width: 50vw;
    margin-bottom: 0px;
}

.resume-end-text {
    display: flex;
    flex-direction: column;
    width: 50vw;
    align-items: center;
}

.resume-ty {
    width: 100%;
    text-align: center;
    margin-bottom: 5vh;
    margin-top: 0px;
}

.resume-end-text h2 {
    font-size: 1rem;
    font-weight: lighter;
}

.download-button {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.resume-end-text a {
    text-decoration: none;
}

.download-img {
    width: 30px;
    height: auto;
    padding: 0px;
}

.resume-download {
    font-weight: 500;
    margin-top: 2px;
    font-size: 1.5rem;
    text-align: center;
    color: white;
}



/* RESPONSIVENESS MOBILE */ 
@media (max-width: 600px) {

    .resume-header {
        font-size: 2.5rem;
        letter-spacing: -1px;
        margin-bottom: 1vh;
        text-align: center;
    }

    .resume-image {
        width: 90vw;
        margin-bottom: 0px;
    }

    .resume-ty {
        width: 90vw;
    }

    .resume-download {
        margin-left: 2vw;
    }

    .download-button {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: center;
    }

    .download-img {
        width: 20px;
        height: auto;
        padding: 0px;
    }
    
};