.footer {
    margin: 0vh auto 1vh;
    width: 50vw;
    display: flex;
    text-align: center;
    justify-content: center;
    bottom: 0;
    font-family: 'Typeface', sans-serif;
    font-size: larger;
}

.form-container {
    padding: 20px;
    margin: 10px;
    width: 85%;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    border: 3px solid white;
    border-radius: 20px;
    background-color: black;
}

.footer-header {
    padding: 20px auto 10px;
    color: white;
    text-align: center;
    letter-spacing: -1px;
    font-size: 2rem;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;

}

.form input[type="text"],  .form input[type="placeholder"], .form input[type="email"] {
    padding: 10px;
    border: 2px solid #ddd;
    border-radius: 10px;
    width: 85%;
    background-color: black;
    color: white;
    font-weight: bolder;
    font-family: 'Typeface', sans-serif;
    font-size: .55em;
}

.form textarea {
    padding: 10px;
    border: 2px solid #ddd;
    border-radius: 10px;
    width: 85%;
    height: 20vh;
    background-color: black;
    color: white;
    font-weight: bolder;
    font-size: .6em;
    font-family: 'Typeface', sans-serif;
}

.submit {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: white;
    padding: 10px 15px;
    margin: 10px auto 10px;
    border: 2px solid #ddd;
    border-radius: 10px;
    cursor: pointer;
    width: 20%;
    letter-spacing: 3px;
}


.submit:hover {
    border: 1px solid greenyellow;
    color: greenyellow;
}

.connect-container {
    margin-top: 30px;
}

.connect-container h2.connect-msg {
    color: #333;
    margin-bottom: 15px;
}

.logos-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.logos-container h3 {
    font-weight: 400;
    text-align: right;
    font-size: 1.6rem;
    justify-content: center;
    margin-top: .8rem;
    margin-bottom: 1.2rem;
}

.logo {
    text-align: center;
}

.github-logo {
    width: 60px;
    height: auto;
}


.linkedin-logo {
    width: 40px;
    height: auto;
}


.logo p {
    color: #666;
    font-size: 14px;
}

.footer-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.top {
    background-color: black;
    color: white;
    padding: 10px 15px;
    margin: 1vh auto 10vh;
    border: 2px solid #ddd;
    border-radius: 10px;
    cursor: pointer;
    width: 20vw;
    letter-spacing: 3px;
}

.block {
    margin-top: 30vh;
    margin-bottom: 10vh;
    width: 90vw;
    border-bottom: 2px solid white;
}

.modal-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 2vh;
    width: 30vw;
    border: 2px solid white;
    border-radius: 20px;
    margin: 5vh auto 10vh;
    font-family: 'Typeface', sans-serif;
    padding: 2rem;
}

/* RESPONSIVENESS MOBILE */ 
@media (max-width: 600px) {
    .footer {
        margin: 0vh auto 1vh;
        width: 100%;
    }

    .footer-header {
        font-size: 1.3rem;
    }

    .logos-container h3 {
        font-size: 1.1rem;
    }
  
    .block {
        margin: 30vh auto 5vh auto;
        width: 90%;
        border-bottom: 2px solid white;
    }

    .submit {
        padding: 15px 20px;
        font-size: .9rem;
        width: 30%;
    }
    
    .top {
        width: 60%;
        margin-bottom: 10vh;
    }
};