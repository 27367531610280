#home-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Typeface', sans-serif;
}
.intro {
    display: flex;
    flex-direction: row;
    margin: auto;
    transition: transform 0.3s ease;
    animation: float 3s ease-in-out infinite;
    text-align: center;
}

.intro:hover {
    transform: translateY(-5px);
}


@keyframes float {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
}

.intro h1 {
    font-size: 5rem;
    letter-spacing: 1px;
    margin-bottom: 1vh;
}

#home-div h2 {
    text-align: center;
    font-size: 1.95rem;
    margin-top: 1vh;
    font-weight: 400;
    font-family: 'Typeface', monospace;
}

.main-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
}

.type-text {
    min-height: 30vh;
    margin: auto;
    display: flex;
    justify-content: center;
    width: 51vw;
}

#projects {
    margin-top: 3.3em;
    padding-top: 1rem;
}


/* RESPONSIVENESS MOBILE */ 
@media (max-width: 600px) {
    .intro h1 {
        font-size: 1.6rem;
        letter-spacing: 1px;
        margin-bottom: 1vh;
    }
    
    #home-div h2 {
        text-align: center;
        font-size: 1.3rem;
        margin-top: 1vh;
        font-weight: 400;
    }

    .type-text {
        min-height: 50vh;
        margin: auto;
        display: flex;
        justify-content: center;
        width: 90vw;
    }
}


 /* RESPONSIVENESS TABLET */
@media (max-width: 768px) {
    .intro h1 {
        font-size: 2rem;
    }

    .type-text h2 {
        font-size: 1rem;
    }
}