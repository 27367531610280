#about-me {
    font-family: 'Typeface', sans-serif;
}

#all-projects-cont {
    display: flex;
    flex-direction: column;
    gap: 5vh;
    justify-content: center;
    padding-top: 7em;
    width: 90vw;
    font-family: 'Typeface', sans-serif;
}

#all-projects-cont .showcase-projects {
    align-items: center;

}

.showcase-projects {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3vw;
    margin-bottom: 10vh;
}

#projects-cont {
    display: flex;
    flex-direction: column;
    gap: 5vh;
    justify-content: center;
    width: 90vw;
    font-family: 'Typeface', sans-serif;
}

#projects-cont .showcase {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3vw;
}

.showcase {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3vw;
    margin-bottom: 10vh;
}

.BTP h1, .HHMDP h1, .MVP h1 {
    text-align: left;
    font-size: 5vh;
    margin: 2vh 0 2vh;
}

.HHMDP p, .MVP p {
    margin: .5vh auto .5vh;
}

.HHMDP img, .MVP img {
    width: 35vw;
    height: auto;
    border-radius: 10px;
}

.BTP {
    display: flex;
    flex-direction: row;
    margin-left: 10vw;
    margin-right: 10vw;
}

.BTP video {
    width: 19vw;
    height: auto;
    border-radius: 10px;
}

.BTP .description {
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding-left: 3vw;
    width: 100%;
}

.BTP p {
    text-align: left;
}

.projects-cont {
    width: 90vw;
    height: auto;
}

.demo-cont {
    border: 2px solid white;
    border-radius: 20px;
    padding: 3em;
}

.project-block {
    margin: 1em 0 1em;
    width: 100%;
    border-bottom: 2px solid white;
}

.github-button {
    background-color: black;
    color: white;
    padding: 12px 1px 10px 3px;
    border: 3px solid #ddd;
    border-radius: 10px;
    margin: 2vh 0 1vh;
    cursor: pointer;
    width: 5vw;
    letter-spacing: 3px;
    font-size:1rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.projects-header {
    font-size: 3rem;
    letter-spacing: -1px;
    margin-bottom: 1vh;
    text-shadow:         
        0 0 5px white,
        0 0 10px white,
        0 0 20px white,
        0 0 40px white;
    color: black;
}

.desc-title {
    display: flex;
    flex-direction: row;
    gap: 1vw;
    width: 100%;
}

.desc-title-phone {
    display: flex;
    flex-direction: row;
    gap: 1vw;
    width: 100%;
}

.desc-title a, .desc-title-phone a {
    text-decoration: none;
}

/* RESPONSIVENESS MOBILE */ 
@media (max-width: 600px) {
    .showcase {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 12vh;
    }

    .HHMDP img, .MVP img {
        width: 100%;
        height: auto;
        border-radius: 10px;
    }

    .demo-cont {
        border-radius: 20px;
        padding: 1.5em;
    }

    .BTP h1, .HHMDP h1, .MVP h1 {
        font-size: 2rem;
    }

    .HHMDP p {
        margin-bottom: 10vh;
    }

    .github-button {
        background-color: black;
        color: white;
        padding: 12px 1px 10px 3px;
        border: 3px solid #ddd;
        border-radius: 10px;
        margin: 2vh 0 1vh;
        cursor: pointer;
        width: 20vw;
        letter-spacing: 3px;
        font-size: 1rem;
    }

    .desc-title {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    .BTP {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 1vw;
        margin-right: 1vw;
    }
    
    .BTP video {
        width: 100%;
        height: auto;
        border-radius: 10px;
    }
    
    .BTP .description {
        display: flex;
        flex-direction: column;
        justify-content: left;
        width: 100%;
    }
    
    .BTP p {
        text-align: left;
    }

    .desc-title-phone {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1vw;
        width: 100%;
    }

    .showcase-projects {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 3vh;
    }

    .projects-header {
        font-size: 2.5rem;
        letter-spacing: -1px;
        margin-bottom: 1vh;
        text-align: center;
    }

    #projects-cont {
        gap: 1vh;
    }

};
