/* ABOUTME.JS */
#about-me {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;
}

.about-me-intro {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5vw;
    margin: 50px;
    margin-bottom: 4vh;
    margin-top: 1px;
}

.profile-pic {
    border-radius: 50%;
    width: 30vw;
    border: .5px solid white;
    box-shadow: 
        0 0 5px white,
        0 0 10px white,
        0 0 20px white,
        0 0 40px white;
    margin-top: 7vh;
}

.who-am-i {
    align-items: center;
    width: 40vw;
    text-align: center;
    transition: transform 0.3s ease;
    animation: float 3s ease-in-out infinite;
    padding-top: 5vh;
}

@keyframes float {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
}

.who-am-i h1 {
    font-size: 5rem;
    margin-bottom: 1vh;
    letter-spacing: -6px;
    text-shadow:    
        0 0 1px black,     
        0 0 5px orange,
        0 0 10px orange,
        0 0 20px orange,
        0 0 40px orange;
}

.intro-summary {
    width: 40vw;
    padding: 10px 15px;
    border-radius: 20px;
}

.intro-summary h2 {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
    text-shadow:  
        0 0 1px black,         
        0 0 5px #00bfff,
        0 0 10px #00BFFF,
        0 0 20px #00BFFF,
        0 0 40px #00BFFF;
}

/* TECHSKILL.JS */
.all-skills-cont {
    display: flex;
    flex-direction: row; 
    gap: 2vw;
    justify-content: left;
}

.skill-box img, .tool-box img {
    width: 7vw;
    height: auto;
    padding: .3vw;
}

.spec-box img {
    width: 6.2vw;
    padding: .6vw;
    height: auto;
}

.skill-box, .tool-box, .spec-box {
    margin: 10px;
}

.skill-box:hover, .tool-box:hover, .spec-box:hover {
    box-shadow: 0px 0px 40px white;
    border-radius: 10%;
    text-shadow: 
        0 0 2px black,
        0 0 4px white,
        0 0 6px white;
}

.tech-skills-cont {
    display: flex;
    flex-direction: column;
    margin-bottom: 5vh;
}

.tech-skills-heading {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.tech-skills-heading h2 {
    font-size: 1.8rem;
    text-shadow: 
    0 0 1px black,
    0 0 3px white,
}

.tool-box p, .skill-box p, .spec-box p {
    text-align: center;
    padding: 10px;
}

.header-tech-skills {
    text-align: center;
    font-size: 5rem;
    letter-spacing: -6px;
    margin-bottom: 1px;
    padding-left: 2vw;
    text-shadow: 
        0 0 2px black,
        0 0 4px white,
        0 0 6px white;
}

.skills-table {
    justify-self: center;
    width: 20vw;
}

/* SOFT SKILLS*/
.soft-skills {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.soft-skills p {
    font-size: 1.5rem;
    font-weight: bolder;
}

.soft-skills-text p:hover {
    transform: scale(1.6); 
    transition: transform 0.3s ease-in-out;
}

.header-ss{
    text-align: center;
    font-size: 5rem;
    letter-spacing: -6px;
    margin-bottom: 1px;
    text-shadow: 
        0 0 2px black,
        0 0 4px white,
        0 0 6px white;
}

.BE-happy {
    margin-right: 2vw;
    width: 13vw;
    height: auto;
}

#ed-ss {
    display: flex;
    flex-direction: row;
}

.soft-skills-cont, .ed-cont {
    width: 50vw;
}


/* ED */
.header-ed {
    text-align: center;
    font-size: 5rem;
    letter-spacing: -6px;
    padding-left: 6vw;
    margin-bottom: 7vh;
    text-shadow: 
        0 0 2px black,
        0 0 4px white,
        0 0 6px white;
}

.schools {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.schools img {
    width: 10vw;
    height: auto;
    padding-left: 8vw;
}

.schools h2 {
    margin-left: 20px;
    padding: auto;
    font-size: 1.35rem;
}

.schools h3 {
    margin-left: 20px;
    padding: auto;
    font-size: 1.2rem;
    font-weight: 500;
}

.bing-text h2:hover, .bing-text h3:hover {
    text-shadow: 
        0 0 5px #00FF00,
        0 0 10px #00FF00,
        0 0 20px #00FF00, 
        0 0 40px #00FF00,
        0 0 80px #00FF00;
}

#flatiron h2:hover {
    text-shadow: 
        0 0 5px #00BFFF,
        0 0 10px #00BFFF,
        0 0 20px #00BFFF,
        0 0 40px #00BFFF,
        0 0 80px #00BFFF; 
}

.schools img {
    width: 10vw;
    height: auto;
    padding-left: 8vw;
}

/* WORK */
.job-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.name {
    text-shadow:    
        0 0 1px black,     
        0 0 5px orange,
        0 0 10px orange,
        0 0 20px orange;
}

.location {
    text-shadow: 
        0 0 1px black,
        0 0 5px #00BFFF,
        0 0 10px #00BFFF,
        0 0 20px #00BFFF;
}

.job-title h2 {
    font-size: 1.35rem;
}

.job-title h3 {
    font-size: 1.2rem;
    font-family: 'Typeface', monospace;
    text-shadow: 
        0 0 1px black,
        0 0 5px white,
        0 0 10px white;
}

.bar {
    text-shadow: 
        0 0 2px black,
        0 0 4px white,
        0 0 6px white,
        0 0 8px white,
        0 0 10px white;
}

.jobs {
    display: flex;
    flex-direction: column;
    width: 60vw;
    margin-right: 2vw;
    margin-left: 3vw;    
}

#work {
    display: flex;
    flex-direction: column;
}

.work-header{
    text-align: left;
    font-size: 5.5rem;
    letter-spacing: -6px;
    margin-bottom: 2.5vh;
    margin-top: 3vh;
    margin-left: 17vw;
    text-shadow: 
        0 0 2px black,
        0 0 4px white,
        0 0 6px white;
}

.job-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: .5rem;
}

/* HOBBIES */
.hobbies {
    display: flex;
    align-items: center;
    flex-direction: column ;
    margin-right: 3vw;
    margin-left: 1vw;   
    width: 30vw;
}

.hobby-img {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.hobbies-cont {
    display: flex;
    flex-direction: column;
}

.hobbies-cont h2 {
    padding: 0rem .1rem 0rem .5rem;
    text-align: left;
}

.carousel-cont{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.carousel-cont h1 {
    margin-top: 1px;
}

.carousel-cont img {
    height: 10vh;
    object-fit: cover;
    margin: 1rem;
    border: .5px solid white;
    box-shadow: 
        0 0 5px white,
        0 0 10px white,
        0 0 20px white,
        0 0 40px white;
}

.header-hobbies {
    text-align: center;
    font-size: 3.5rem;
    letter-spacing: -3px;
    margin-bottom: .1vh;
    margin-top: 2.5vh;
    text-shadow: 
        0 0 2px black,
        0 0 4px white,
        0 0 6px white;
}

.header-shows {
    text-align: center;
    font-size: 3.5rem;
    letter-spacing: -3px;
    padding-top: 6vh;
    margin-bottom: .4vh;
    text-shadow: 
        0 0 2px black,
        0 0 4px white,
        0 0 6px white;
}

.button-p {
    transform: rotate(-45deg);
    height: 3rem;
    width: 3rem;
    background-color: rgba(0, 0, 0, 0.0000001);
    border: none;
    border-top: 1px solid white;
    border-left: 1px solid white;
    cursor: pointer;
}

.button-n {
    transform: rotate(45deg);
    height: 3rem;
    width: 3rem;
    background-color: rgba(0, 0, 0, 0.0000001);
    border: none;
    border-top: 1px solid white;
    border-right: 1px solid white;
    cursor: pointer;
}

.hi {
    display: flex;
    flex-direction: row;
}

.button-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.button-cont img {
    width: 13vw;
    height: auto;

}

#work-hobbies {
    display: flex;
    flex-direction: row;
}

.running h2 {
    padding:1px;
    text-align: left;
    font-size: 1.5rem;
    margin-top: 1vh;
    margin-bottom: 1vh;
    margin-left: 1vw;
    letter-spacing: 1px;
}

.music h2 {
    padding:1px;
    text-align: right;
    margin-top: 1vh;
    margin-bottom: 1vh;
    font-size: 1.5rem;
}

.live-music {
    letter-spacing: 1px;
}

.BE-eating {
    width: 5vw;
    height:auto;
    padding: 1px 1px 1px 3px;
}

.BE-cycling {
    width: 5vw;
    height: auto;

}


/* RESPONSIVENESS MOBILE */ 
@media (max-width: 600px) {
    .about-me-intro {
        display: flex;
        flex-direction: column;
    }

    .who-am-i {
        width: 95vw;
        padding-top: 1px;
    }
    
    .who-am-i h1 {
        font-size: 3rem;
        margin-bottom: 1vh;
        letter-spacing: -1px;
        margin-top: 1vh;
    }

    .intro-summary {
        width: 95%;
    }

    .intro-summary h2 {
        font-size: .9rem;
    }

    .all-skills-cont, #work-hobbies {
        flex-direction: column;
        justify-content: center;
    }

    .tech-skills-heading {
        align-items: center;
    }

    .header-tech-skills, .header-ed, .header-ss, .header-hobbies, .header-hobbies, .work-header{
        font-size: 3.5rem;
        letter-spacing: -2px;
        margin-bottom: 1px;
        text-align: center;
        margin: auto;
    }

    .work-header {
        margin-top: 5vh;
    }

    .skill-box img, .tool-box img {
        width: 20vw;
        height: auto;
        padding: .3vw;
    }
    
    .spec-box img {
        width: 17vw;
        padding: .6vw;
        height: auto;
    }

    #ed-ss, #work, #hobbies, .hobbies {
        display: flex;
        flex-direction: column;
        width: 95vw;
    }

    #ed-ss, .hobbies {
        gap: 5vh;
    }

    .ed-cont, .soft-skills-cont {
        width: 100%;
        align-items: center;
    }

    .BE-happy {
        margin-right: 2vw;
        width: 30vw;
        height: auto;
    }

    .soft-skills-text p {
        font-size: 1rem;
    }

    .job-header h2 {
        font-size: .55rem;
    }

    .job-title h3 {
        font-size: .6rem;
    }

    .jobs {
        width: 95%;
    }

    .button-cont img {
        width: 50vw;
        height: auto;
    }

    .hobbies-cont {
        width: 80vw;
        align-items: center;
    }

    .BE-eating {
        width: 30vw; 
    }

    .BE-cycling {
        width: 25vw;
    }

    .profile-pic {
        margin-top: 4vh;
    }

    .schools img {
        width: 20vw;
    }

    #flatiron h2 {
        font-size: 1rem;
    }

    .bing-text h2 {
        font-size: 1rem;
    }

    .bing-text h3 {
        font-size: .8rem;
    }
}