body {
  margin: 0;
  font-family: 'Helvetica', sans-serif;
  color: white;
  background: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
